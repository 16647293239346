export class GetTcSecondaryCodes {
  static readonly type = '[Secondary Code Entities] Get TC Secondary Codes';

  constructor(public readonly tcId: MsApp.Guid) {}
}

export class InvalidateTcSecondaryCodes {
  static readonly type = '[Secondary Code Entities] Invalidate TC Secondary Codes';

  constructor(public readonly tcId: MsApp.Guid) {}
}
